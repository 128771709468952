<template>
    <div class="p-4">
        <b-card>
            <b-card-title style="margin-bottom: 45px">
                TRANSACTIONS
            </b-card-title>
            <vue-table :pageSizes="[10, 20, 50, 100]" ref="table" :url="apiBase" :fields="fields" :perPage="10">
                <template slot="actions" slot-scope="props">
                    <div class="btn-group">
                        <b-btn variant="primary" size="sm" v-if="props.rowData.refund" @click="viewDetails(props.rowData)">
                            <b-icon-eye></b-icon-eye>
                            View
                        </b-btn>
                    </div>
                </template>
            </vue-table>
            <b-modal id="transactionDetails" title="Transaction Details" :no-close-on-backdrop="true"
                     modal-class="modal-right"
                     size="lg" hide-footer>
                <transaction-details-page :detail="detail_id"></transaction-details-page>
            </b-modal>
        </b-card>
    </div>
</template>

<script>
import urls from '../../../data/urls';
import TransactionDetailsPage from './TransactionDetails';

export default {
    name: 'ListTransaction',
    components: { TransactionDetailsPage },
    data () {
        return {
            apiBase: urls.admin.transaction.list,
            detail_id: '',
            fields: [
                {
                    name: 'transaction_id',
                    sortField: 'transaction_id',
                    title: 'Transaction ID',
                    titleClass: ''
                },
                {
                    name: 'user',
                    sortField: 'user',
                    title: 'User',
                    titleClass: ''
                },
                {
                    name: 'phone_number',
                    sortField: 'phone_number',
                    title: 'Phone',
                    titleClass: ''
                },
                {
                    name: 'initiated_date_new',
                    sortField: 'initiated_date_new',
                    title: 'Initiated Date',
                    titleClass: ''
                },
                {
                    name: 'amount',
                    sortField: 'amount',
                    title: 'Amount',
                    titleClass: ''
                },
                {
                    name: 'status',
                    sortField: 'status',
                    title: 'Status',
                    titleClass: ''
                },
                {
                    name: 'transaction_mode',
                    sortField: 'transaction_mode',
                    title: 'Transaction Mode',
                    titleClass: ''
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        viewDetails (rowData) {
            this.$router.push('/admin/transaction/' + rowData.id + '/details');
        }
    }
};
</script>

<style scoped>

</style>
