import { render, staticRenderFns } from "./ListTransaction.vue?vue&type=template&id=1015c37e&scoped=true&"
import script from "./ListTransaction.vue?vue&type=script&lang=js&"
export * from "./ListTransaction.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1015c37e",
  null
  
)

export default component.exports