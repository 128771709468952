<template>
    <loading-animation v-if="loading"></loading-animation>
    <div v-else>
        <div v-if="details" class="card m-4 p-4">
            <div class="row mb-3">
                <div class="col-md-4">
                    <div class="mb-1">Transaction</div>
                    <span class="text-muted" v-if="details.transaction">{{ details.transaction }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Initiated Date</div>
                    <span class="text-muted" v-if="details.initiated_date">{{ details.initiated_date }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Refund Amount</div>
                    <span class="text-muted" v-if="details.refund_amount">{{ details.refund_amount }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <div class="mb-1">Refund Status</div>
                    <span class="text-muted" v-if="details.refund_status">{{ details.refund_status }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Refund Transaction Id</div>
                    <span class="text-muted" v-if="details.refund_transaction_id">{{ details.refund_transaction_id }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Refund Payu Id</div>
                    <span class="text-muted" v-if="details.refund_pay_u_id">{{ details.refund_pay_u_id }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <div class="mb-1">Response</div>
                    <textarea class="text-muted" v-if="details.response" disabled
                              style="background-color: white; height: 15vh;" v-model="details.response"></textarea>
                    <span class="text-muted" v-else>Nil</span>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <div class="mb-1">Refund Completed Data</div>
                    <span class="text-muted" v-if="details.refund_completed_date">{{ details.refund_completed_date }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Refund Payu Session Id</div>
                    <span class="text-muted"
                          v-if="details.refund_pay_u_session_id">{{ details.refund_pay_u_session_id }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'transactionDetailsPage',
    // props: {
    //     detail: { type: Number }
    // },
    data () {
        return {
            id: this.$route.params.id,
            details: '',
            loading: false
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        loadDetails () {
            const that = this;
            that.loading = true;
            axios.form(urls.admin.transaction.details, { transaction_id: that.id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.details = json.refund;
                    that.loading = false;
                } else {
                    that.$notify('Error');
                }
            });
        }
    }
};
</script>

<style scoped>
.text-small {
    font-weight: 500;
}
</style>
